define('ember-idx-tabs/tab-panel', ['exports', 'ember', 'ember-idx-utils/mixin/with-config', 'ember-idx-utils/mixin/style-bindings'], function (exports, Em, WithConfigMixin, StyleBindingsMixin) {

  'use strict';

  //(c) 2014 Indexia, Inc.
  var computed = Em['default'].computed;

  exports['default'] = Em['default'].Component.extend(WithConfigMixin['default'], StyleBindingsMixin['default'], {
    classNameBindings: ['styleClasses'],
    styleClasses: Em['default'].computed(function () {
      var _ref;
      return (_ref = this.get('config.tabs.tabPanelClasses')) != null ? _ref.join(" ") : void 0;
    }),
    styleBindings: ['height'],
    attributeBindings: ['selected'],

    /**
     * The ancestor `Tabs` component
     * @property tabs
     * @type Tabs
     */
    tabs: computed.alias('parentView'),

    /**
     * A reference to the {{#crossLink "TabList}}TabList{{/crossLink}} instance.
     *
     * @property tabList
     * @type TabList
     */
    tabList: computed.alias('parentView.tabList'),

    /**
     * A reference to the {{#crossLink "Tabs}}{{/crossLink}}'s panels property.
     *
     * @property panels 
     * @type Array
     */
    panels: computed.alias('parentView.panels'),

    /**
     * The tab that refer to this tab pane
     *
     * @property tab
     * @type Tab
     */
    tab: Em['default'].computed('panels.length', 'tabList.tab_instances.@each', function () {
      var index, tabs;
      index = this.get('panels').indexOf(this);
      tabs = this.get('tabList.tab_instances');
      return tabs && tabs.objectAt(index);
    }),
    selected: Em['default'].computed('tab', 'tab.selected', function () {
      return this.get('tab.selected');
    }),
    changeVisibility: Em['default'].observer('selected', function () {
      return this.$().css('display', this.get('selected') ? "" : 'none');
    }),
    register: Em['default'].on('didInsertElement', function () {
      return this.get('tabs').addTabPanel(this);
    }),
    unregister: Em['default'].on('willDestroyElement', function () {
      return this.get('tabs').removeTabPanel(this);
    })
  });

});